import { NFTStorage } from "nft.storage";
import { Client, Wallet, convertStringToHex } from "xrpl";

export async function createIPFSData(storageKey, data) {
  const before = new Date();
  const nftstorage = new NFTStorage({ token: storageKey });

  // call client.store, passing in the image & metadata
  const blob = new Blob([JSON.stringify(data)], {
    type: "application/json",
  });

  nftstorage.storeBlob(blob).then(() => {
    const after = new Date();
    console.log(
      `createIPFSData_ m before: ${before.getMilliseconds()}, m after: ${after.getMilliseconds()}, diff: ${
        after.getMilliseconds() - before.getMilliseconds()
      }`
    );
  });
  return await nftstorage.storeBlob(blob);
}

export async function mintNft(walletSeed, ipfsId) {
  const rn = new Date();
  const client = new Client("wss://s.altnet.rippletest.net:51233");
  const wallet = Wallet.fromSeed(walletSeed);

  const transactionBlob = {
    TransactionType: "NFTokenMint",
    Account: wallet.address,
    URI: convertStringToHex(ipfsId),
    Flags: 8,
    TransferFee: 0,
    NFTokenTaxon: 0,
  };

  client.connect().then(() => {
    client
      .submitAndWait(transactionBlob, { wallet: wallet })
      .then(() => {
        console.log(
          `mintNFT_ m before: ${rn.getMilliseconds()}, m after: ${new Date().getMilliseconds()}, diff: ${
            new Date().getMilliseconds() - rn.getMilliseconds()
          }`
        );
      })
      .catch((err) => console.error("error: ", err));
  });
}
