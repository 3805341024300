import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db, auth } from "./config";
import {
  GoogleAuthProvider,
  signInWithCredential,
  signInWithPopup,
} from "firebase/auth";
import {
  COURSES_COLLECTION,
  GOOGLE_ACCESS_TOKEN,
  GOOGLE_ID_TOKEN,
  SCHOOL_USERS_COLLECTION,
  USERS_COLLECTION,
} from "../utils";

const provider = new GoogleAuthProvider();

export const getUser = async (email) => {
  const docRef = doc(db, USERS_COLLECTION, email);
  const docSnap = await getDoc(docRef);

  return docSnap.exists() && docSnap.data();
};

export const findUser = async (email) => {
  const docRef = doc(db, USERS_COLLECTION, email);
  const docSnap = await getDoc(docRef);

  return docSnap.exists();
};

export const createUser = async (email, data) => {
  const usersRef = collection(db, USERS_COLLECTION);
  await setDoc(doc(usersRef, email), data).catch((err) => console.error(err));
};

export const signInWithGoogle = async () => {
  const result = await signInWithPopup(auth, provider).catch((error) =>
    console.error(error)
  );

  const credential = GoogleAuthProvider.credentialFromResult(result);
  sessionStorage.setItem(GOOGLE_ID_TOKEN, credential.idToken);
  sessionStorage.setItem(GOOGLE_ACCESS_TOKEN, credential.accessToken);
  return result.user;
};

export const signInWithAuthKey = async (idToken, accessToken) => {
  const cred = GoogleAuthProvider.credential(idToken, accessToken);

  const result = await signInWithCredential(auth, cred).catch((error) =>
    console.error("error: ", error)
  );
  return result.user;
};

export const getAllStudents = async (email) => {
  const justUrl = email.split("@")[1];

  const docRef = doc(db, SCHOOL_USERS_COLLECTION, justUrl);
  const docSnap = await getDoc(docRef);

  return docSnap.exists() && docSnap.data();
};

export const removeStudent = async (institutionEmail, studentEmail) => {
  const institution = getDomain(institutionEmail);
  const docRef = doc(db, SCHOOL_USERS_COLLECTION, institution);
  const inst = await getDoc(docRef);
  const data = inst.data();
  const instStudents = data["students"];

  const newStudents = instStudents.filter(
    (student) => student !== studentEmail
  );

  await updateDoc(docRef, {
    students: newStudents,
  });
};

export const addNft = async (email, nftData) => {
  const docRef = doc(db, USERS_COLLECTION, email);
  const user = await getDoc(docRef);
  const userData = user.data();

  if (userData["nfts"]) {
    const oldList = userData["nfts"];
    oldList.push(nftData);

    await updateDoc(docRef, {
      nfts: oldList,
    });
  } else {
    await updateDoc(docRef, {
      nfts: [nftData],
    });
  }
};

export const getNfts = async (email) => {
  const docRef = doc(db, USERS_COLLECTION, email);
  const user = await getDoc(docRef);
  return user.data()["nfts"];
};

export const getDomain = (email) => email.split("@")[1];

export const addStudent = async (institutionEmail, studentEmail) => {
  const justUrl = getDomain(institutionEmail);
  const schoolUsersRef = collection(db, SCHOOL_USERS_COLLECTION);
  const allUsersRef = collection(db, USERS_COLLECTION);
  const thisSchoolRef = doc(schoolUsersRef, justUrl);

  const docSnap = await getDocs(allUsersRef);
  let studentExists = false;

  docSnap.forEach((doc) => {
    if (studentEmail === doc.id) {
      studentExists = true;
    }
  });

  if (studentExists) {
    const docData = await getDoc(thisSchoolRef);
    const oldData = docData.data()["students"];

    oldData.push(studentEmail);

    await updateDoc(thisSchoolRef, {
      students: oldData,
    }).catch((err) => console.error(err));

    return true;
  } else {
    return false;
  }
};

export const addCourse = async (data) => {
  const coursesRef = collection(db, COURSES_COLLECTION);
  await setDoc(doc(coursesRef), data).catch((err) => console.error(err));
};

export const getCourses = async () => {
  const coursesRef = collection(db, COURSES_COLLECTION);
  const docSnap = await getDocs(coursesRef);

  const res = [];
  docSnap.forEach((doc) => {
    res.push(doc.data());
  });

  return res;
};
