import { Button, Input, Radio } from "antd";
import { useState } from "react";
import { createUser } from "../firebase";
import { USER_EMAIL } from "../utils";
import { useNavigate } from "react-router-dom";

export const CreateUser = () => {
  const [userEmail, setUserEmail] = useState(
    sessionStorage.getItem(USER_EMAIL)
  );
  const [xrpWalletSeed, setXrpWalletSeed] = useState();
  const [nftStorageKey, setNftStorageKey] = useState();
  const [institutionName, setInstitutionName] = useState();
  const [userType, setUserType] = useState("student");
  const navigate = useNavigate();

  async function handleCreateUser() {
    await createUser(userEmail, {
      nftStorageKey,
      xrpWalletSeed,
      userType,
      institutionName,
    });
    sessionStorage.removeItem(USER_EMAIL);
    navigate("/dashboard");
  }

  return (
    <div className="create-user-container">
      <h3>create new user</h3>
      <Input
        className="create-user-input"
        style={{ backgroundColor: "grey" }}
        placeholder="Email Address"
        value={userEmail}
        autoComplete="off"
        disabled
        onChange={(e) => setUserEmail(e.target.value)}
      />
      <Input
        className="create-user-input"
        placeholder="XRP Wallet Seed"
        type="password"
        onChange={(e) => setXrpWalletSeed(e.target.value)}
      />
      <Input
        className="create-user-input"
        placeholder="NFT Storage Address"
        type="password"
        onChange={(e) => setNftStorageKey(e.target.value)}
      />
      {userType === "institution" && (
        <Input
          className="create-user-input"
          placeholder="Institution Name"
          onChange={(e) => setInstitutionName(e.target.value)}
        />
      )}
      <Radio.Group
        value={userType}
        onChange={(e) => setUserType(e.target.value)}
      >
        <Radio.Button value="student">Student</Radio.Button>
        <Radio.Button value="institution">Institution</Radio.Button>
      </Radio.Group>

      <Button style={{ marginTop: "12px" }} onClick={handleCreateUser}>
        create user
      </Button>
    </div>
  );
};
