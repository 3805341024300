import { useEffect, useState } from "react";
import {
  addNft,
  getAllStudents,
  getCourses,
  getDomain,
  getUser,
  signInWithAuthKey,
} from "../../firebase";
import { Button, Table, notification } from "antd";
import { createIPFSData, mintNft } from "../../blockchain";
import { GOOGLE_ACCESS_TOKEN, GOOGLE_ID_TOKEN } from "../../utils";
import { useNavigate } from "react-router-dom";

const studentColumns = [
  {
    title: "Email",
    dataIndex: "name",
    render: (record) => <>{record}</>,
  },
];

const courseColumns = [
  {
    title: "Name",
    dataIndex: "courseName",
    render: (record) => <>{record}</>,
  },
];

export const Mint = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectionType] = useState("checkbox");
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [email, setEmail] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const idToken = sessionStorage.getItem(GOOGLE_ID_TOKEN);
    const accessToken = sessionStorage.getItem(GOOGLE_ACCESS_TOKEN);

    if (!idToken || !accessToken) {
      setTimeout(() => navigate(""), 3000);
    }

    async function signIn() {
      setIsLoading(true);
      const user = await signInWithAuthKey(idToken, accessToken);
      setEmail(user.email);
      setIsLoading(false);
    }

    async function fetchData() {
      const studentData = await getAllStudents(email);
      const courseData = await getCourses();
      setStudents(studentData.students);
      setCourses(courseData);
    }

    email ? fetchData() : signIn();
  }, [email]);

  async function handleMintNFT() {
    setIsLoading(true);
    selectedCourses.forEach(async (course) => {
      selectedStudents.forEach(async (student) => {
        const user = await getUser(student.name);
        const { xrpWalletSeed, nftStorageKey } = user;

        if (xrpWalletSeed && nftStorageKey) {
          const before = new Date();
          const ipfsId = await createIPFSData(nftStorageKey, course);
          await mintNft(xrpWalletSeed, ipfsId);
          notification.open({
            message: "NFT Distributed",
            description: `Issued NFT "${course.courseName}" for ${student.name}.`,
          });
          await addNft(student.name, {
            issuer: getDomain(email),
            ipfsId,
            ...course,
          });
          const after = new Date();
          console.log(
            `wholeThing_ m before: ${before.getMilliseconds()}, m after: ${after.getMilliseconds()}, diff: ${
              after.getMilliseconds() - before.getMilliseconds()
            }`
          );
        } else {
          notification.open({
            message: "Error distributing NFT",
            description: `There was an issue distributing ${course.courseName} for ${student.name}.`,
          });
        }
      });
    });

    setIsLoading(false);

    navigate("/dashboard");
  }

  const courseRowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedCourses(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const studentRowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedStudents(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div className="some-container">
      <h3>mint an NFT</h3>
      <h3>select the course(s)</h3>
      {courses && (
        <Table
          rowSelection={{
            type: selectionType,
            ...courseRowSelection,
          }}
          columns={courseColumns}
          dataSource={courses.map((course, i) => {
            return { key: i, ...course };
          })}
          pagination={false}
        />
      )}
      <h3>select the student(s)</h3>
      {students && (
        <Table
          rowSelection={{
            type: selectionType,
            ...studentRowSelection,
          }}
          columns={studentColumns}
          dataSource={students.map((student, i) => {
            return { key: i, name: student };
          })}
          pagination={false}
        />
      )}

      {isLoading ? (
        <b>Loading...</b>
      ) : (
        <>
          <Button style={{ margin: "12px" }} onClick={handleMintNFT}>
            mint
          </Button>
          <Button
            style={{ margin: "12px" }}
            onClick={() => navigate("/dashboard")}
          >
            back to dashboard
          </Button>
        </>
      )}
    </div>
  );
};
