import { Button, Input } from "antd";
import { useEffect, useState } from "react";
import { addStudent, signInWithAuthKey } from "../../firebase";
import { GOOGLE_ACCESS_TOKEN, GOOGLE_ID_TOKEN } from "../../utils";
import { useNavigate } from "react-router-dom";

export const AddStudent = () => {
  const [institutionEmail, setInstitutionEmail] = useState();
  const [studentEmail, setStudentEmail] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const idToken = sessionStorage.getItem(GOOGLE_ID_TOKEN);
    const accessToken = sessionStorage.getItem(GOOGLE_ACCESS_TOKEN);
    if (!idToken || !accessToken) {
      navigate("");
    }

    async function signIn() {
      const user = await signInWithAuthKey(idToken, accessToken);
      setInstitutionEmail(user.email);
    }

    signIn();
  }, []);

  async function handleAddStudent() {
    const res = await addStudent(institutionEmail, studentEmail);
    if (res) {
      navigate("/dashboard/manage");
      // toast notification
    } else {
      // toast notification for failure
    }
  }

  return (
    <div className="some-container">
      <h3>add new student</h3>
      <Input
        className="create-user-input"
        placeholder="Student Email"
        onChange={(e) => setStudentEmail(e.target.value)}
      />

      <Button style={{ marginTop: "12px" }} onClick={handleAddStudent}>
        add student
      </Button>
      <Button
        style={{ marginTop: "8px" }}
        onClick={() => navigate("/dashboard/manage")}
      >
        back to students
      </Button>
    </div>
  );
};
