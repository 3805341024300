import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactJson from "react-json-view";
import { Button } from "antd";

export const ViewNft = () => {
  const navigate = useNavigate();
  const { nftId } = useParams();
  const [nftData, setNftData] = useState(null);

  useEffect(() => {
    fetch(`https://${nftId}.ipfs.nftstorage.link`)
      .then((res) => res.json())
      .then((data) => setNftData(data));
  }, []);
  return (
    <div className="some-container" style={{ maxHeight: "fit-content" }}>
      {nftData && <ReactJson theme={"apathy:inverted"} src={nftData} />}
      <Button
        style={{ marginTop: "8px" }}
        onClick={() => navigate("/dashboard")}
      >
        back to dashboard
      </Button>
    </div>
  );
};
