import { useEffect, useState } from "react";
import { getUser, signInWithAuthKey } from "../../firebase";
import { Client, Wallet } from "xrpl";
import { Button, Space, Table } from "antd";
import { logOut } from "../../api";
import { GOOGLE_ACCESS_TOKEN, GOOGLE_ID_TOKEN } from "../../utils";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    title: "Issuer",
    key: "issuer",
    render: (_, record) => (
      <div
        style={{
          textOverflow: "ellipsis",
          maxWidth: "200px",
          overflow: "hidden",
        }}
      >
        {record.issuer}
      </div>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (_, record) => (
      <div
        style={{
          textOverflow: "ellipsis",
          maxWidth: "200px",
          overflow: "hidden",
        }}
      >
        {record.courseName}
      </div>
    ),
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <a href={`dashboard/view/${record.ipfsId}`}>View</a>
      </Space>
    ),
  },
];

export const Overview = () => {
  const [email, setEmail] = useState();
  const [xrpWalletAddress, setXrpWalletAddress] = useState();
  const [nfts, setNfts] = useState([]);
  const [otherNfts, setOtherNfts] = useState([]);
  const [userType, setUserType] = useState([]);
  const [client] = useState(new Client("wss://s.altnet.rippletest.net:51233"));
  const navigate = useNavigate();

  useEffect(() => {
    console.log(nfts);
  }, [nfts]);
  useEffect(() => {
    const idToken = sessionStorage.getItem(GOOGLE_ID_TOKEN);
    const accessToken = sessionStorage.getItem(GOOGLE_ACCESS_TOKEN);
    if (!idToken || !accessToken) {
      navigate("");
    }

    async function signIn() {
      const user = await signInWithAuthKey(idToken, accessToken);
      setEmail(user.email);
    }

    signIn();
  }, []);

  useEffect(() => {
    async function findUser() {
      const userData = await getUser(email);
      setXrpWalletAddress(userData.xrpWalletSeed);
      setUserType(userData.userType);
      setOtherNfts(userData.nfts);
    }

    if (email) {
      findUser();
    }
  }, [email]);

  useEffect(() => {
    if (xrpWalletAddress) {
      const wallet = Wallet.fromSeed(xrpWalletAddress);

      client.connect().then(() => {
        client
          .request({
            method: "account_nfts",
            account: wallet.address,
          })
          .then((res) => setNfts(res.result.account_nfts));
      });
    }
  }, [xrpWalletAddress]);

  return (
    <div className="some-container">
      <p>{email}</p>

      {userType === "student" && (
        <>
          <p>Your NFTs</p>
          <Table columns={columns} dataSource={otherNfts} pagination={false} />
        </>
      )}

      {userType === "institution" && (
        <>
          <Button onClick={() => navigate("/dashboard/mint")}>
            distribute NFTs
          </Button>
          <div style={{ padding: "8px" }}></div>
          <Button onClick={() => navigate("/dashboard/manage")}>
            manage students
          </Button>
        </>
      )}
      <div style={{ padding: "8px" }}></div>
      <Button onClick={logOut}>log out</Button>
    </div>
  );
};
