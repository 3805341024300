import { useEffect, useState } from "react";
import { getUser, signInWithAuthKey } from "../../firebase";
import { Button, Input } from "antd";
import { mintNft } from "../../blockchain";
import { GOOGLE_ACCESS_TOKEN, GOOGLE_ID_TOKEN } from "../../utils";
import { useNavigate } from "react-router-dom";

export const MintUnique = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [teacher, setTeacher] = useState("");
  const [college, setCollege] = useState("");
  const [grade, setGrade] = useState("");

  const [xrpWalletSeed, setXrpWalletSeed] = useState();
  const [storageKey, setStorageKey] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const idToken = sessionStorage.getItem(GOOGLE_ID_TOKEN);
    const accessToken = sessionStorage.getItem(GOOGLE_ACCESS_TOKEN);
    if (!idToken || !accessToken) {
      setTimeout(() => navigate(""), 3000);
    }

    async function signIn() {
      const user = await signInWithAuthKey(idToken, accessToken);
      const userData = await getUser(user.email);
      setXrpWalletSeed(userData.xrpWalletSeed);
      setStorageKey(userData.nftStorageKey);
      setIsLoading(false);
    }

    signIn();
  }, []);

  async function handleMintNFT() {
    setIsLoading(true);
    await mintNft(xrpWalletSeed, storageKey, {
      name,
      description,
      properties: { teacher, college, grade },
    });
    setIsLoading(false);
  }

  return (
    <div className="create-user-container">
      <h3>mint an NFT</h3>

      {isLoading ? (
        <p>{xrpWalletSeed ? "Minting NFT" : "Getting Wallet"}...</p>
      ) : (
        <>
          <Input
            className="create-user-input"
            placeholder="Digital Systems"
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            className="create-user-input"
            placeholder="Some college course"
            onChange={(e) => setDescription(e.target.value)}
          />
          <Input
            className="create-user-input"
            placeholder="Professor Einstein"
            onChange={(e) => setTeacher(e.target.value)}
          />
          <Input
            className="create-user-input"
            placeholder="Manhattan College"
            onChange={(e) => setCollege(e.target.value)}
          />
          <Input
            className="create-user-input"
            placeholder="A, B+, C, etc.."
            onChange={(e) => setGrade(e.target.value)}
          />
          <Button onClick={handleMintNFT}>mint</Button>
          <p style={{ fontSize: "10px" }}>
            Recipient XRP Wallet Seed: {xrpWalletSeed}
          </p>
        </>
      )}
    </div>
  );
};
