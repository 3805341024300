import { useEffect, useState } from "react";
import {
  getAllStudents,
  removeStudent,
  signInWithAuthKey,
} from "../../firebase";
import { Button, Space, Table } from "antd";
import { GOOGLE_ACCESS_TOKEN, GOOGLE_ID_TOKEN } from "../../utils";
import { useNavigate } from "react-router-dom";

export const Manage = () => {
  const [students, setStudents] = useState([]);
  const [email, setEmail] = useState();
  const navigate = useNavigate();

  const handleDelete = async (institutionEmail, studentEmail) => {
    await removeStudent(institutionEmail, studentEmail);
    const schoolData = await getAllStudents(email);
    setStudents(schoolData.students);
  };

  const columns = [
    {
      title: "ID",
      render: (_, record) => <div>{record.name}</div>,
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space size="middle">
          <Button danger onClick={() => handleDelete(email, record.name)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const idToken = sessionStorage.getItem(GOOGLE_ID_TOKEN);
    const accessToken = sessionStorage.getItem(GOOGLE_ACCESS_TOKEN);
    if (!idToken || !accessToken) {
      navigate("");
    }

    async function signIn() {
      const user = await signInWithAuthKey(idToken, accessToken);
      setEmail(user.email);
    }

    async function getStudents() {
      const schoolData = await getAllStudents(email);
      setStudents(schoolData.students);
    }

    signIn();
    if (email) {
      getStudents();
    }
  }, [email]);

  return (
    <div className="some-container">
      <h3>Your students</h3>
      <Table
        pagination={false}
        columns={columns}
        dataSource={students.map((student, i) => {
          return { key: i, name: student };
        })}
      />
      <Button
        style={{ marginTop: "8px" }}
        onClick={() => navigate("/dashboard/add-student")}
      >
        add a student
      </Button>
      <Button
        style={{ marginTop: "8px" }}
        onClick={() => navigate("/dashboard")}
      >
        back to dashboard
      </Button>
    </div>
  );
};
