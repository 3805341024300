import { Route, Routes } from "react-router-dom";
import { Overview } from "./overview";
import { Manage } from "./manage";
import { ViewNft } from "./viewNft";
import { AddStudent } from "./addStudent";
import { Mint } from "./mint";
import { MintUnique } from "./mintUnique";

export const Dashboard = () => (
  <Routes>
    <Route path="" element={<Overview />} />
    <Route path="manage" element={<Manage />} />
    <Route path="view/:nftId" element={<ViewNft />} />
    <Route path="add-student" element={<AddStudent />} />
    <Route path="mint" element={<Mint />} />
    <Route path="mint-unique" element={<MintUnique />} />
  </Routes>
);
