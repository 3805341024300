import { Route, Routes } from "react-router-dom";
import { Home } from "./components/home";
import { CreateUser } from "./components/createUser";
import { Dashboard } from "./components/dashboard/index";
import "./App.css";

export default function App() {
  return (
    <div className="app-container">
      <Routes>
        <Route index element={<Home />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/create-user" element={<CreateUser />} />
      </Routes>
    </div>
  );
}
