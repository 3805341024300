import { Button } from "antd";
import { findUser, signInWithGoogle } from "../firebase";
import { useState } from "react";
import { USER_EMAIL } from "../utils";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [userExists, setUserExists] = useState(null);
  const [userEmail, setUserEmail] = useState();
  const navigate = useNavigate();

  async function handleContinue() {
    setIsLoading(true);
    setMessage("logging in with Google...");
    const user = await signInWithGoogle();

    setMessage("checking for an account...");
    const userExists = await findUser(user.email);
    setUserEmail(user.email);
    setUserExists(userExists);
    setIsLoading(false);

    if (userExists) {
      setMessage(`Found account for ${user.email}`);
      setTimeout(() => navigate("/dashboard"), 3000);
    }
  }

  async function handleCreateUser() {
    sessionStorage.setItem(USER_EMAIL, userEmail);
    navigate("/create-user");
  }

  return (
    <div className="some-container">
      <h1>educhain</h1>

      {userExists && (
        <>
          <p>{message}</p>
          <p>redirecting you...</p>
        </>
      )}

      {userExists === false && (
        <>
          <p>No user found for {userEmail}</p>
          <Button onClick={handleCreateUser}>Create new user</Button>
        </>
      )}

      {!isLoading && userExists === null && (
        <>
          <p>{message}</p>
          <Button onClick={handleContinue}>Continue with Google</Button>
        </>
      )}
    </div>
  );
};
